import React, {useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MeetingData } from '../Types';
import './MeetingDetails.scss';
import AttendeesList from "../attendees/AttendeesList";
import { convertStringDateToJsonDate, convertStringDateToJsonTime, floorLabel} from '../Utils';
import { t } from '../../languages/Translations';
import {Confirm} from '../common/Confirm';
import { AgendaIcon } from "../common/Icons";
import {deleteMeeting} from "../../api/meeting";

function Header(MeetingData: { value: MeetingData }) {
  return (
    <div className="meeting-header">
      <h2>{MeetingData.value.name}</h2>
    </div>
  );
}

export function MeetingInformation(MeetingData: { value: MeetingData }) {
  // check format to support detail from card or from reservation create
  console.log("day:"+MeetingData.value.day);
  const day:string = new String(MeetingData.value.day).match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)
      ? MeetingData.value.day
      : convertStringDateToJsonDate(MeetingData.value.day);
  console.log("from:"+MeetingData.value.fromTime);
  const fromTime: string = new String(MeetingData.value.fromTime).match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/)
      ? MeetingData.value.fromTime.substring(0, 5)
      : convertStringDateToJsonTime(MeetingData.value.fromTime);
  console.log("to:"+MeetingData.value.toTime);
  const toTime: string = new String(MeetingData.value.toTime).match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/)
      ? MeetingData.value.toTime.substring(0, 5)
      : convertStringDateToJsonTime(MeetingData.value.toTime);
  // render
  return (
    <div className="container column">
      <div className="meeting-info">
        <img src="/icons/agenda.svg" alt="" />
        <div className="meeting-detail-info">
          <span>{day}&nbsp;&nbsp;-&nbsp;&nbsp;{fromTime} / {toTime}</span>
          <span></span>
        </div>
      </div>
      <div className="meeting-info">
        <img src="/icons/compass.svg" alt="" />
        <div className="meeting-detail-info">
          <span>{MeetingData.value.room.label}</span>
          <span>{floorLabel(MeetingData.value.room.floor)}</span>
        </div>
      </div>
      <div className="meeting-info">
        <img src="/icons/building.svg" width="20px" height="20px" alt="" />
        <div className="meeting-detail-info">
          <span>{MeetingData.value.room.location?.label}</span>
          <span>{MeetingData.value.room.location?.addresses[0]?.fullName}</span>
        </div>
      </div>
    </div>
  );
}

function MeetingDetails(props: { location, setLoading, displayToaster }) {
  let history = useHistory();
  const { location, setLoading, displayToaster } = props;
  const details = location.state as MeetingData;
  const [displayConfirm, setDisplayConfirm] = useState(false)



  function confirmDelete() {
    setDisplayConfirm(true);
  }

  function cancelConfirm() {
    setDisplayConfirm(false);
  }

  function deleteMeetingConfirmed() {
    cancelConfirm();
    if(details.code) {
      deleteMeeting(details.code).then((res) => {
        setLoading(false);
        displayToaster({ message: t("new_meeting_form.delete_succeed"), error: false })
        history.push("/home")
      }).catch(e => {
        setLoading(false);
        let msg = t("bo_errors."+e);
        if(msg == null || msg.length == 0) msg = t("new_meeting_form.delete_error")+" ("+e+")"
        displayToaster({ message: msg, error: true  })
      });
    }
  }

  function CancelButton() {
    return (
      <button onClick={confirmDelete} className="meeting-button cancel-button">
       {t('meeting_detail.cancel_button')}
      </button>
    );
  }

  return (
    <>
      <div className="header-img"></div>
      <div className="modal">
        <div className="content-padding">
          <div className="meetings-button-container" >
            <Link to="/home" className="meeting-button" ><AgendaIcon value="orange" />{t('common.my_meetings')}</Link>
          </div>
          <Header value={details} />
          <div className="meeting-container">
            <div className="meeting-info-container">
              <MeetingInformation value={details}  />
              <div className="access-container">
            {details.badge?.tokenBinary ? 
              <div className="badge-container">
                <h3>QR-Code d'accès</h3>
                <img src={`data:image/png;base64,${details.badge?.tokenBinary}`} height="200" width="200"/>
              </div>
              :
              <h3>Code d'accès : {details.badge?.tokenSerial}</h3>
            }
            </div>
              <CancelButton />
            </div>
            <div className="attendees-container">
              <div className="attendees-number">{details.attendees.length + " " + t('common.attendees_number_label')}</div>
              <AttendeesList attendees={details.attendees} />
            </div>        
            {displayConfirm ? <Confirm title={t('common.confirmation')} content={t('home_screen.cancel_meeting')} handleConfirm={() => deleteMeetingConfirmed()} handleCancel={() => cancelConfirm()} /> : null}
          </div>          
        </div>
      </div>
    </>
  );
}

export default MeetingDetails;
