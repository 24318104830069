import React, { useState} from "react";
import {Link, useLocation, useHistory} from 'react-router-dom';
import {Formik} from 'formik';
import './Login.scss';
import * as Yup from 'yup';
import { loginUser } from "../../api/user";
import { FATextField } from "../common/form/FATextField";
import { BigButton } from "../common/FAButtons";
import { ForwardArrowIcon } from "../common/Icons";
import { t } from '../../languages/Translations'
import Cookies from "universal-cookie";
import {userContext} from "../App";
import {currentToken} from "../Utils";
import {AuthData} from "../Types";
import ReCAPTCHA from "react-google-recaptcha";

const initValues: AuthData = {email:'', password:'', captcha:''};
let recaptchaInstance;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Login({value:{ setLoading, setLoggedIn }}) {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [captcha, setCaptcha] = useState("");
  const history = useHistory();
  const query = useQuery();

  const resetRecaptcha = () => {
        recaptchaInstance.reset();
  };

  // login can be called from email with already defined params
  initValues.email = query.get("e") ? query.get("e") : "";
  initValues.password = query.get("p") ? query.get("p") : "";

  function onCaptcha(value) {
      setCaptcha(value);
  }

  function login(credentials:AuthData) {
      // check captcha
      if(!captcha || captcha.length < 10) {
          setErrorMsg(t('common.captcha'))
          setError()
          return;
      }
      //
      setIsError(false);
      setLoading(true);
      credentials.captcha = captcha;
      userContext.email = credentials.email
      //
      loginUser(credentials)
          .then((user) => {
              setLoading(false);
              setLoggedIn(true)
              if(!currentToken()) {
                  if(window.location.hostname === "localhost") {
                      const today = new Date();
                      new Cookies().set("token", user.token, {expires: (new Date(today.setDate(today.getDate() + 7)))});
                  }
                  window.location.href = "/";
              }
              else {
                  if (!user.credentialUpdateRequired) {
                      userContext.member = user.member;
                      userContext.token = user.token;
                      userContext.pwdchange = false;
                      history.push("/home")
                  } else {
                      userContext.pwdchange = true;
                      history.push("/password")
                  }
              }
           })
          .catch(e => {
              setLoading(false);
              setLoggedIn(false)
              setErrorMsg(t('login_screen.connexion_error')+ " ("+e+")")
              resetRecaptcha();
              setError()
          });
  }

  function setError() {
    setIsError(true);
    setTimeout(function () {
      setIsError(false);
    }, 5000)
  }

  return (
    <div className="login-form">
      <Formik
        initialValues={initValues}
        onSubmit={login}
        validationSchema={Yup.object().shape({
          email: Yup.string().matches(/^[a-zA-Z0-9.-_]+@[a-zA-Z0-9-_]+(?:\.[a-zA-Z0-9-]+)*$/, " ").required(" "),
          password: Yup.string().required(" ")
        })}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <div className="login-error">{isError && <span>{errorMsg}</span>}</div>
            <div>
                <p>&nbsp;</p>
                <FATextField
                  label={t('login_screen.login_label')}
                  name="email"
                  type="text"
                  autocomplete={true}
                />
                <FATextField
                  label={t('login_screen.password_label')}
                  name="password"
                  type="password"
                  autocomplete={false}
                />
                <ReCAPTCHA sitekey="6LfDitkZAAAAADt6HjXIV19rGmEFe_pqr-ZTYjLF" onChange={onCaptcha}  ref={e => recaptchaInstance = e}/>
                <br/>
                <BigButton type="submit" label={t('login_screen.connexion_button')} color="orange" size="big" icon={<ForwardArrowIcon value="white" />} handleClick={() => { }} />
            </div>
          </form>
        )}
      </Formik>
        <p/><p/>
      <span><Link className='register-link' to="/reset">{t('login_screen.forgot_password')}</Link>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link className='register-link' to="/register">{t('login_screen.register')}</Link></span>
    </div>
  );
}

export { Login };
