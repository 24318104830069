import React, {useEffect, useState} from "react";
import {Form, Formik, FormikValues} from 'formik';
import './Register.scss';
import * as Yup from 'yup';
import { BigButton } from "../common/FAButtons";
import {ForwardArrowIcon} from "../common/Icons";
import { t } from '../../languages/Translations'
import {RegisterFormData} from "../Types";
import {STEP_REGISTER_TITLES, STEP_REGISTER_BUTTONS} from "../Constant";
import {RegisterStepCgu} from "./RegisterStepCgu";
import {RegisterStepForm} from "./RegisterStepForm";
import {registerUser} from "../../api/user";
import {RegisterConfirm} from "./RegisterConfirm";
import ReactDOM from "react-dom";
import {useHistory} from "react-router-dom";

type RegisterFormType = {
    initialValues: RegisterFormData,
    onSubmit: (val: RegisterFormData) => void,
    children: FormikValues
}


function Register({value:{ setLoading, displayToaster }}) {

    function submit(val: RegisterFormData) {
        if(!val.captcha || val.captcha.length < 10) {
            displayToaster({ message: t("common.captcha"), error: true })
            return;
        }
        setLoading(true);
        registerUser({...val})
            .then((res) => {
                setLoading(false)
                ReactDOM.render(<RegisterConfirm value={{ email: val.email, message: null }}/>, document.getElementById('form'));
            }).catch(e => {
            setLoading(false)
            let msg = t("register_screen.error")+ " (" + e + ")";
            if(!e.toString().startsWith("http:")) {
                switch(e.toString()) {
                    case "9120":
                        msg = t("register_screen.errors.invalid_code");
                        break;
                    case "9122":
                        msg = t("register_screen.errors.invalid_company");
                        break;
                    case "9123":
                        msg = t("register_screen.errors.already_registred");
                        break;
                    case "9124":
                        msg = t("register_screen.errors.invalid_domain");
                        break;
                    case "9097":
                        msg = t("register_screen.errors.invalid_captcha");
                        break;
                }
            }
            ReactDOM.render(<RegisterConfirm value={{ email: val.email, message: msg }}/>, document.getElementById('form'));
        });
    }

  return (
      <RegisterForm
          initialValues={{
              accept: false,
              email: "",
              codeA: "",
              codeB: "",
              firstname: "",
              lastname: "",
              captcha: undefined
          }}
          onSubmit={submit}
      >
          <RegisterStepCgu value={{ setLoading, displayToaster }}
              validationSchema={Yup.object().shape({
                  accept: Yup.boolean().oneOf([true], "You must accept the terms and conditions")
              })}
          />
          <RegisterStepForm value={{ setLoading, displayToaster }}
              validationSchema={Yup.object().shape({
                  email: Yup.string().matches(/^[a-zA-Z0-9.-_]+@[a-zA-Z0-9-_]+(?:\.[a-zA-Z0-9-]+)*$/, " ").required(" "),
                  codeA: Yup.string().matches(/^9[0-9]{3}$/, " ").required(" "),
                  codeB: Yup.string().matches(/^[0-9]{4}$/, " ").required(" "),
                  firstname: Yup.string().matches(/^[a-zA-Z0-9\-._àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' ]+$/, " "),
                  lastname: Yup.string().matches(/^[a-zA-Z0-9\-._àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' ]+$/, " ")
              })}

          />
      </RegisterForm>
  );

}

const RegisterForm: React.FunctionComponent<RegisterFormType> = (form) => {

    const [stepNumber, setStepNumber] = useState(0);
    const steps = React.Children.toArray(form.children);

    const [snaphsot, setSnapshot] = useState(form.initialValues);

    const step = steps[stepNumber] as FormikValues;
    const totalSteps = steps.length;
    const isLastStep = stepNumber === totalSteps - 1;

    const previous = (values: RegisterFormData) => {
        setSnapshot(values);
        setStepNumber(Math.max(stepNumber - 1, 0));
    }

    const next = (values: RegisterFormData) => {
        setSnapshot(values);
        setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    }
    const handleSubmit = async (values: RegisterFormData) => {
        if (isLastStep) {
            return form.onSubmit(values);
        } else {
            next(values);
        }
    }

    ///Start handling resizing
    const { height, width } = useWindowDimensions();

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowDimensions;
    }
    ///End handling resizing

    function RenderButton(formik) {
        if (stepNumber === 0) {
            const accept = formik.values.accept
            return <BigButton width={width} height={height} label={STEP_REGISTER_BUTTONS[stepNumber]} disabled={!accept} handleClick={() => { }} icon={<ForwardArrowIcon value={accept ? "white" : "orange"} />} />
        }
        else if (stepNumber === 1) {
            const enable = formik.values.email && formik.values.codeA &&  formik.values.codeB
            return <BigButton width={width} height={height} label={STEP_REGISTER_BUTTONS[stepNumber]} disabled={!enable} handleClick={() => { }} icon={<ForwardArrowIcon value={enable ? "white" : "orange"} />} />
        }
     }

    return (
        <Formik
            initialValues={snaphsot}
            onSubmit={handleSubmit}
            validationSchema={step.props.validationSchema}
        >
            {formik => (
                <Form id="form">
                    <div className={stepNumber === 1 ? 'header-img' : ''}></div>
                    <div className={stepNumber === 1 ? 'modal form-content' : 'form-content'}>
                        <Stepper
                            step={stepNumber}
                            title=""
                            handleBack={() => previous(formik.values)}
                        />
                        {step}
                        <div className="next right">
                            {RenderButton(formik)}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

// -----STEPPER ----
type StepperType = {
    step: number,
    title: string,
    handleBack: () => void
}
const Stepper: React.FunctionComponent<StepperType> = (stepper) => {

    const history = useHistory();

    const routeChange = () =>{
        history.push("/login");
    }

    function getStepTitle(step: number) {
        return STEP_REGISTER_TITLES[step];
    }

    function renderArrow(step: number) {
        if (step == 0) {
            return <button type="button" onClick={routeChange}><img src="/icons/backArrow.svg" alt="" /></button>;
        }
        if (step > 0) {
            return <button type="button" onClick={stepper.handleBack}><img src="/icons/backArrow.svg" alt="" /></button>;
        }
    }

    return (
        <div className="pagination">
            <div className="pagination-bullets">
                <span className={`pagination-bullet ${stepper.step === 0 ? 'pagination-bullet-active' : ''}`}></span>
                <span className={`pagination-bullet ${stepper.step === 1 ? 'pagination-bullet-active' : ''}`}></span>
            </div>
            <div className="title-container">
                {stepper.step < 2 && renderArrow(stepper.step)}
                <h2>{getStepTitle(stepper.step)}</h2>
            </div>
        </div>
    );
}

export { Register };
